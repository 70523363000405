import { useTenant, useTenantSetting } from "@nestoca/multi-tenant";
import { Divider, HStack, Typography } from "@nestoca/ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight } from "react-icons/bs";

import styles from "../burger-menu/burger-menu.module.scss";

import { LanguageSelector } from "./language-selector";

export const SupportLinksHub = () => {
  const tenant = useTenant();
  const { locale } = useRouter();
  const { t } = useTranslation("common");

  const {
    settings: {
      faq: { en: faqEnUrl, fr: faqFrUrl },
    },
  } = useTenantSetting("tenantUrls");

  if (!tenant) {
    return null;
  }
  const faqUrl = locale === "en" ? faqEnUrl : faqFrUrl;

  return (
    <>
      <Divider />
      <LanguageSelector changePreferredLanguage={false} />
      <>
        {!!faqUrl && (
          <>
            <Divider />
            <Link
              href={faqUrl}
              target="_blank"
              className={styles["burger-menu__link-wrapper"]}
            >
              <HStack
                className={styles["burger-menu__link"]}
                justify="between"
                align="center"
              >
                <Typography size={0} weight={5}>
                  {"FAQ"}
                </Typography>
                <BsBoxArrowUpRight />
              </HStack>
            </Link>
          </>
        )}
        <Divider />
        <Link
          href={
            locale === "en"
              ? tenant.privacyPolicyURL
              : tenant.privacyPolicyFRURL || ""
          }
          target="_blank"
          className={styles["burger-menu__link-wrapper"]}
        >
          <HStack className={styles["burger-menu__link"]} justify="between">
            <Typography size={0} weight={5}>
              {t("privacyPolicy")}
            </Typography>
            <BsBoxArrowUpRight />
          </HStack>
        </Link>
      </>
    </>
  );
};
